import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Stack from "@mui/material/Stack";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from "@tanstack/react-query";

import { createClient, getClients, getExistingClients } from 'api/services/Clients';
import { ApplicationMode, PowerUpUser } from 'types';

import './Editor.css';
import style from './Editor.module.scss';


function ClientEditor() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  
  const onClientCreationSuccess = (data: any) => {
    setClientName("")
    enqueueSnackbar(
    <div>Client account created for <b>{data.data.client_name}</b></div>,
    {
      variant: "success",
      action: ()=>null,
    });
    queryClient.invalidateQueries({ queryKey: ['clients'] });
    queryClient.invalidateQueries({ queryKey: ['get-clients-created-elsewhere'] });
  }

  const onClientCreationError = (error: any) => {
    enqueueSnackbar(`Client creation failed: ${error.response.data}`, {
      variant: "error",
      persist: true,
    });
  }
  
  const [clientId, setClientId] = useState(-1);
  const [clientName, setClientName] = useState("");
  const [applicationMode, setApplicationMode] = useState(ApplicationMode.BESS);
  
  const { data: existingClients, isLoading: existingClientsAreLoading } = getExistingClients();
  const { data: clientsData, error: clientsError, isLoading: clientsIsLoading} = getClients();
  const {
    mutate: mutateCreateClient,
    isPending: isClientCreationLoading
  } = createClient(onClientCreationSuccess, onClientCreationError);

  const existingCientNames = useMemo(() => {
    if (existingClients)
      // Filter only clients which has an ID, so we can reuse the same information
      return existingClients.filter((user: PowerUpUser) => user.clientId !== null).map((user: PowerUpUser) => user.name)
    else
      return []
  } ,[existingClients])

  const handleClientNameSelection = (clientName: string) => {
    const selectedClient: PowerUpUser = existingClients.find((u: PowerUpUser) => u.name === clientName)
    if (selectedClient) {
      setApplicationMode(selectedClient.mode as ApplicationMode)
      setClientId(Number(selectedClient.clientId));
    }
  }

  const onClientCreateSubmit = (id: number | null, name: string, email: string | null, mode: string) => {
    // Create Client account and PowerUp user
    mutateCreateClient({
      "client_id": id,
      "client_name": name,
      "powerup_user_email": email,
      "application_mode": mode,
    });
  };

  const userEmailAddress = useMemo(() => {
    let email = "";

    if (clientName)
      email = clientName.normalize('NFKD')
               .replace(/\p{Diacritic}/gu, "")
               .replace(/[^a-zA-Z0-9 ]/gi, '')
               .replace(' ', '_')
               .concat("@powerup.com")
    return email
  }, [clientName]);

  const handleClientCreation = useCallback(() => {
    if (clientId > 0) {
      const selectedUser: PowerUpUser = existingClients.find((u: PowerUpUser) => Number(u.clientId) === clientId)
      onClientCreateSubmit(clientId, clientName, selectedUser.email, applicationMode);
    } else {
      onClientCreateSubmit(null, clientName, null, applicationMode);
    }
  }, [clientName, applicationMode])


  return (
    <>
      <div className={style.editor}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <h1>Create Client Account</h1>
            <form onSubmit={(ev) => {
              ev.preventDefault();
              handleClientCreation();
            }}>
              <Typography sx={{ fontStyle: 'italic', typography: 'subtitle2', color: 'grey', paddingBottom: '30px' }}>
                The client creation also create a powerup user, saved in Bitwarden,
                that you can use to log into the client account.
              </Typography>
              <Stack spacing={3}>
                <Stack spacing={3} direction={"row"} alignItems={"center"}>
                  <Autocomplete
                    disabled={isClientCreationLoading || existingClientsAreLoading}
                    id={"combo-box-existing-clients"}
                    value={clientName}
                    freeSolo
                    selectOnFocus
                    options={existingCientNames}
                    sx={{ width: 300 }}
                    renderInput={(params) =>
                      <TextField {...params}
                        label={"Client Name"}
                        helperText={"Name should have at least 3 characters"}
                        onChange={e => setClientName(e.target.value)}
                      />
                    }
                    onChange={(e, newValue: any) => {
                      if (typeof newValue === 'string') {
                        setClientName(newValue);
                        handleClientNameSelection(newValue);
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setClientName(newValue.inputValue);
                        setClientId(-1);
                      } else {
                        setClientName(newValue);
                        setClientId(-1);
                      }
                      e.preventDefault();
                    }}
                  />
                  {existingClientsAreLoading &&
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '255px',
                        left: '550px',
                      }}
                    />
                  }
                  <TextField
                    disabled
                    id={"email-preview"}
                    type={"text"}
                    label={"PowerUp Email address preview"}
                    value={userEmailAddress || ''}
                    InputLabelProps={{ shrink: true }}
                    variant={"outlined"}
                    helperText={"Will be use to log into the client account"}
                  />
                  <TextField
                    disabled={isClientCreationLoading}
                    value={applicationMode}
                    label={"Application Mode"}
                    variant={"outlined"}
                    select
                    sx={{ width: '200px', mt: 3 }}
                    onChange={e => setApplicationMode(e.target.value as ApplicationMode)}
                    helperText={" "}
                  >
                  {Object.values(ApplicationMode).map((mode) =>
                    <MenuItem key={`select-mode-${mode}`} value={mode}>{mode}</MenuItem>
                  )}
                </TextField>
                </Stack>
                <Stack spacing={3} direction={"row"} alignItems={"center"}>
                  <Button
                    disabled={isClientCreationLoading || clientName?.length < 3}
                    type={"submit"}
                    variant={"contained"}
                    color={"primary"}
                    sx={{ height: '50px', width: '100px' }}
                  >
                    { isClientCreationLoading ? <CircularProgress size={"25px"}  sx={{'color': 'white'}} thickness={5}/>
                      : "Submit" }
                  </Button>
                  {isClientCreationLoading &&
                    <Typography component={"span"} sx={{ typography: 'subtitle2', color: 'grey' }}>
                      <i>This might take a while to complete. Go make a cup of coffee </i>
                      <span className={"coffee"}>☕︎</span>
                      <i> ...Come back, the client should be created.</i>
                    </Typography>
                  }
                </Stack>
              </Stack>

            </form>
          </Grid>
          <Grid item xs={4}>
            {(clientsIsLoading) && <CircularProgress />}
            {(!clientsIsLoading) && clientsData &&
              <div>
                <h3>Current client accounts</h3>
                <ul>
                  {clientsData.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
                    .map((u: { name: string, id: number }) => (
                      <li key={u.id}>ID: <b>{u.id}</b> / Name: <b>{u.name}</b></li>
                    ))
                  }
                </ul>
              </div>
            }
          </Grid>
        </Grid>
      </div>
      {(clientsError) && <div>Error loading clients</div>}
    </>
  )
}

export default ClientEditor;