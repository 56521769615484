import { useContext } from "react";

import { NodeSettingsContext } from "providers/NodeSettingsProvider";
import Logo from "./clientEditorComponents/Logo";
import style from './Editor.module.scss';
import ApiKeys from "./clientEditorComponents/ApiKeys";
import Users from "./clientEditorComponents/Users";
import Assets from "./clientEditorComponents/Assets";

import Box from "@mui/material/Box";

function ClientEditor() {
  const { clientId, clientName } = useContext(NodeSettingsContext);

  return (
    <div className={style.editor}>
      <h1>{clientName} (ID: {clientId})</h1>
      <Box display={"grid"} gridTemplateColumns={"repeat(auto-fit, minmax(400px, 1fr))"} gap={2}>
        <Assets />
        <Users />
        <Logo />
        <ApiKeys />
      </Box>
    </div>
  )
}

export default ClientEditor;