import { ApiBackend, useAuthApi, useAuthMutation } from 'api';
import { enqueueSnackbar } from 'notistack';

export const getClientUsers = (clientId: string) => {
  return useAuthApi(
    [`get-users-${clientId}`],
    `/clients/${clientId}/users`,
    ApiBackend.BACKOFFICE,
    { enabled: !!clientId }
  )
};

export const setMaxAccountsCount = (clientId: string) => (
  useAuthMutation(
    [`set-maximum_accounts_count-${clientId}`],
    `/clients/${clientId}/max_accounts_count`,
    ApiBackend.BACKOFFICE,
    {
      onSuccess: () => {
        enqueueSnackbar('max_accounts_count Saved', { variant: 'success', action: ()=>null, });
      },
      onError: () => {
        enqueueSnackbar('Unable to save max_accounts_count', { variant: 'error', persist: true, });
      },
      retry: false,
    },
    'put',
    {"content-type": "application/json"},
  )
);
