import { getClientAndNodes } from "api/services/Clients";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Client, Node } from 'types';

const defaultState = {
  clientsNodes: undefined,
  nodeId: "",
  clientId: "",
  name: "",
  clientName: "",
  settings: {},
};

interface INodeSettingsContext {
  clientsNodes?: Client[];
  nodeId: string;
  clientId: string;
  name: string;
  clientName: string,
  settings: any;
  saveSettings?: (data: any) => void;
}

export const NodeSettingsContext = React.createContext<INodeSettingsContext>(defaultState);


type Props = {
  children: ReactNode
}

export const NodeSettingsProvider: React.FC<Props> = ({ children }) => {
  const [name, setName] = useState(defaultState.name);
  const [clientName, setClientName] = useState(defaultState.clientName);
  const [settings, setSettings] = useState(defaultState.settings);

  const { data: clientsNodes } = getClientAndNodes();

  const { nodeId, clientId } = useParams();

  useEffect( () => {
    if (!nodeId || !clientId || !clientsNodes) return;

    const nodeName = clientsNodes
      .find( (client:Client) => client.id === Number(clientId))
      .nodes.find( (node:Node) => node.nodeid === nodeId )
      .node_name || nodeId;

    setName(nodeName);
  }, [nodeId, clientId, clientsNodes]);

  useEffect(() => {
    if (!clientId || !clientsNodes) return;

    const clientName = clientsNodes
      .find( (client:Client) => client.id === Number(clientId))
      .name;
    
    setClientName(clientName)
  }, [clientId, clientsNodes])

  const saveSettings = (data: any) => {
    setSettings(data);
  }

  if (!clientsNodes) return null;

  return (
    <NodeSettingsContext.Provider
      value={{
        clientsNodes,
        nodeId: nodeId || "",
        clientId: clientId || "",
        name,
        clientName,
        settings,
        saveSettings,
      }}
    >
      {children}
    </NodeSettingsContext.Provider>
  );
};

export const useNodeSettings = () => useContext(NodeSettingsContext);