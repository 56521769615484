import { useAuthApi, useAuthMutation, ApiBackend } from "api";
import { enqueueSnackbar } from 'notistack';

export const getClients = () => (
  useAuthApi(['clients'], '/clients', ApiBackend.BACKOFFICE)
);

export const getExistingClients = () => useAuthApi(
  [`get-clients-created-elsewhere`],
  `/clients/created_elsewhere`,
  ApiBackend.BACKOFFICE,
  { refetchOnWindowFocus: false }
);

export const getClientAndNodes = () => (
  useAuthApi(['clients', 'nodes'], '/clients_and_nodes', ApiBackend.BACKOFFICE, { refetchOnWindowFocus: false })
);

export const getClientNodes = (clientId: string) => (
  useAuthApi([`client-nodes-${clientId}`], `/clients/${clientId}/nodes`, ApiBackend.BACKOFFICE, { enabled: !!clientId })
);

export const createEss = (clientId: string, callback?: (data: any) => void) => (
  useAuthMutation(
    [`create-ess-${clientId}`],
    `/park/${clientId}/ess`,
    ApiBackend.BIM,
    {
      onSuccess: (data) => {
        enqueueSnackbar('New ESS created', { variant: 'success', action: ()=>null, });
        if (callback) callback(data);
      },
      onError: () => {
        enqueueSnackbar('Unable to create ESS', { variant: 'error', persist: true, });
      },
      retry: false
    },
    'post',
    { "content-type": "application/json" },
  )
);

export const createClient = (onSuccess: (data: any) => void, onError: (error: any) => void) => (
  useAuthMutation(
    [`create-client`],
    `/clients`,
    ApiBackend.BACKOFFICE,
    {
      onSuccess: (data: any) => {
        onSuccess(data);
      },
      onError: (error: any) => {
        onError(error)
      },
      retry: false,
    },
    'post',
    { "content-type": "application/json" },
  )
)