import { AppBar, Box, Chip, Toolbar } from '@mui/material';
import Button from '@mui/material/Button';
import logo from 'assets/powerUpLogo.svg';
import { Link } from 'react-router-dom';

import style from './Navbar.module.scss';

function Navbar() {

  return (
    <nav className={style.navbar}>
      <Box sx={{ flexGrow: 1, borderBottom: 1, borderColor: 'grey.A400' }}>
        <AppBar position={"static"} sx={{ bgcolor: 'white' }} elevation={0}>
          <Toolbar>
            <div className={style.logo}>
              {logo && <img src={logo} alt={'PowerUp'} />}
            </div>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Chip
                label={`Back Office - ${process.env.REACT_APP_ENVIRONMENT?.toUpperCase()}`}
                className={`${style.title} ${style[process.env.REACT_APP_ENVIRONMENT ?? "unknown"]}`}
              />
            </Box>
            <Button
              sx={{"margin-right": "5px"}}
              variant={"outlined"}
              href={"https://dev.azure.com/poweruptech/SaaS_Analytics/_wiki/wikis/Product%20Development%20Wiki/63/Onboarding-Battery-Insight"}
            >
                Documentation
            </Button>
            <Button variant={"contained"} component={Link} to={"/create-client"}>Create client</Button>
          </Toolbar>
        </AppBar>
      </Box>
    </nav>
  )
}

export default Navbar;