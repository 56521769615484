import { useAuthApi, useAuthMutation, ApiBackend } from "api";
import { enqueueSnackbar } from 'notistack';

export const getClientLogo = (clientId: string) => (
  useAuthApi([`client-logo-${clientId}`], `/clients/${clientId}/logo`, ApiBackend.BACKOFFICE,
    { enabled: !!clientId },
    { responseType: 'blob' },
  )
);

export const setClientLogo = (clientId: string, callback?: () => void) => (
  useAuthMutation(
    [`post-logo-${clientId}`],
    `/clients/${clientId}/logo`,
    ApiBackend.BACKOFFICE,
    {
      onSuccess: () => {
        enqueueSnackbar("Logo uploaded", { variant: 'success', action: ()=>null, });
        if (callback) callback();
      },
      onError: () => {
        enqueueSnackbar('Unable to upload logo', { variant: 'error', persist: true, });
      },
      retry: false
    },
    "post",
    { "content-type": "image/png" },
  )
);