export type Client = {
  id: number;
  name: string;
  nodes: Node[];
}

export type PowerUpUser = {
  name: string; // Client name
  email: string;
  clientId: string | null;
  mode: string;
}

export type Node = {
  nodeid: string;
  node_name: string;
  children?: Node[];
}

export enum ApplicationMode {
  BESS = 'BESS',
  EV = 'EV',
  skipper_sensor = 'skipper_sensor'
}