import { getClientLogo, setClientLogo } from "api/services/ClientLogo";
import { NodeSettingsContext } from "providers/NodeSettingsProvider";
import { ChangeEventHandler, useCallback, useContext, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";

import IconButton from "@mui/material/IconButton";
import UploadIcon from "@mui/icons-material/UploadFile";

import Card from "components/editor/Card";

import style from './Logo.module.scss';

function Logo() {
    const queryClient = useQueryClient();
    const { clientId } = useContext(NodeSettingsContext);
    const { data, error, isLoading } = getClientLogo(clientId);
    const [uploadedLogo, setUploadedLogo] = useState<Blob|null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    
    const onSuccess = () => {
        queryClient.invalidateQueries({ queryKey: [`client-logo-${clientId}`] })
    }
    const { mutate: mutatePostLogo, isPending } = setClientLogo(clientId, onSuccess);

    const onLogoSubmit = async () => {
        if (uploadedLogo && uploadedLogo instanceof Blob) {
            const formData = new FormData();
            formData.append(
                "file",
                uploadedLogo
            )
            await mutatePostLogo(formData, { onSuccess: () => setDialogOpen(false) });
        } else {
            enqueueSnackbar("uploadedLogo ain't well formatted", { variant: 'error', persist: true, })
        }
    }

    const handleFileChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        if (event.target.files && event.target.files.length > 0) {
            setUploadedLogo(event.target.files[0])
        };
        event.preventDefault();
    }, []);

    const Actions = useMemo(() => 
        <IconButton onClick={()=>setDialogOpen(true)}>
            <UploadIcon />
        </IconButton>
    , []);

    return (
        <Card title={"Client Logo"} subtitle={"It is displayed in the upper-left corner of the Battery Insight web UI"} actions={Actions}>
        {(isLoading) && <CircularProgress />}
        {(!isLoading && !!error) && <span>Error loading client logo</span>}
        {(!isLoading && !error) &&
            <div className={style.clientLogoContainer}>
                <img src={URL.createObjectURL(data as Blob)}></img>
            </div>
        }
        <Dialog open={dialogOpen} onClose={()=>setDialogOpen(false)}>
            <DialogTitle>Upload Logo</DialogTitle>
            <DialogContent>
            <TextField type={"file"} inputProps={{accept: "image/png"}} onChange={handleFileChange}></TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setDialogOpen(false)} variant={"contained"}>Cancel</Button>
                <Button type={"submit"} onClick={onLogoSubmit} disabled={!uploadedLogo || isPending}>Upload</Button>
            </DialogActions>
        </Dialog>
    </Card>)
}

export default Logo