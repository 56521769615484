import { NodeSettingsContext } from "providers/NodeSettingsProvider";
import { useContext, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";

import { getClientUsers, setMaxAccountsCount } from 'api/services/Users';
import Card from "components/editor/Card";

function Users() {
    const { clientId } = useContext(NodeSettingsContext);

    const { data, error, isLoading } = getClientUsers(clientId);

    const onMaxAccountsCountSubmit = (max_accounts_count: number) => {
        mutateSetMaxAccountsCount({
            "max_accounts_count": max_accounts_count
        });
    };

    const { mutate: mutateSetMaxAccountsCount } = setMaxAccountsCount(clientId);
    const [maxAccountsCount, setStateMaxAccountsCount] = useState(5);
    return (
        <Card title={"Users management"}>
            {(isLoading) && <CircularProgress />}
            {(!isLoading && !!error) && <span>Error loading client users</span>}
            {(!isLoading && !error) && <>
                <span>Current user accounts</span>
                <ul>
                    {data?.users.map((u: { email: string, id: string, role: string, essid: number }) => (
                        <li key={u.id}>Email: <b>{u.email}</b> / Role: <b>{u.role}</b> / essid: <b>{u.essid}</b></li>
                    ))}
                </ul>
                <span>Number of users (including PowerUp ones): {data["current_accounts_count"]}</span>
                <br />
                <span>Current maximum user accounts: {data["max_accounts_count"] ? data["max_accounts_count"] : "Not set. defaults to 5"}</span>
                <br />
            </>}
            <form>
                <label>Set maximum users accounts count: </label>
                <input type={"number"} min={1} value={maxAccountsCount} onChange={e => setStateMaxAccountsCount(parseInt(e.target.value))}></input>
                <button onClick={(ev) => { onMaxAccountsCountSubmit(maxAccountsCount); ev.preventDefault(); }}>Submit</button>
            </form>
        </Card>)
}

export default Users