import { Outlet } from 'react-router-dom';
import LeftSidebar from 'components/layout/LeftSidebar';
import Navbar from 'components/layout/Navbar';

import style from './Settings.module.scss';

function ClientOrNodeSettings() {
  return (
      <div className={style.page}>
        <Navbar />
        <div className={style.container}>
          <LeftSidebar />
          <Outlet/>
        </div>
      </div>
  )
}

export default ClientOrNodeSettings;