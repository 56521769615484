import { Configuration, PopupRequest } from '@azure/msal-browser';

const msalConfig: Configuration = {
  auth: {
    clientId: String(process.env.REACT_APP_AZURE_APPLICATION_ID),
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: String(process.env.REACT_APP_AZURE_REDIRECT_URI),
    navigateToLoginRequestUrl: true,

  },
}

export default msalConfig;

export const loginRequest: PopupRequest = {
  scopes: [`${process.env.REACT_APP_AZURE_SCOPE}`],
};
