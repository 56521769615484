
import { type PropsWithChildren } from "react";

import Box from '@mui/material/Box';
import CardContent from "@mui/material/CardContent";
import MuiCard from "@mui/material/Card";
import Typography from "@mui/material/Typography";

type Props = PropsWithChildren<{size?: number, title?: string, actions?: any, subtitle?: string}>

export default function Card({children, size=1, title, subtitle, actions}: Props) {
  return (
    <MuiCard elevation={0} sx={{gridColumn: `span ${size}`, border: 'solid 1px #ddd'}}>
      <CardContent sx={{padding: 0}}>
        <Box display={'flex'} flexDirection={"column"} p={3}>
          <Box display={'flex'} alignItems={'center'}>
          { title && <Typography variant={'h5'} fontWeight={'bold'}>{title}</Typography> }
          { actions }
          </Box>
          { subtitle && <Typography variant={'caption'}>{subtitle}</Typography>}
        </Box>
        <Box px={3}>{ children }</Box>
      </CardContent>
    </MuiCard>
  )
}