import { useAuthApi, useAuthMutation, ApiBackend } from 'api';
import { enqueueSnackbar } from 'notistack';

export const getNodeData = (clientId: string, nodeId: string) => {
  return useAuthApi(
    [`get-node-data-${clientId}-${nodeId}`],
    `/node_settings/${clientId}/${nodeId}`,
    ApiBackend.BACKOFFICE,
    { enabled: !!clientId && !!nodeId }
  )
  };

export const getQaNodeData = (clientId: string, nodeId: string) => {
  return useAuthApi(
    [`get-qa-node-data-${clientId}-${nodeId}`],
    `/node_settings/${clientId}/${nodeId}`,
    ApiBackend.QA_BACKOFFICE,
    { enabled: !!clientId && !!nodeId }
  )
  };

export const postNodeData = (clientId: string, nodeId: string, callback?: (data: any) => void) => (
  useAuthMutation(
    [`set-node-data-${clientId}-${nodeId}`],
    `/node_settings/${clientId}/${nodeId}`,
    ApiBackend.BACKOFFICE,
    {
      onSuccess: (data) => {
        enqueueSnackbar('Node settings Saved', { variant: 'success', action: ()=>null, });
        if (callback) callback(data);
      },
      onError: () => {
        enqueueSnackbar('Unable to save node settings', { variant: 'error', persist: true, });
      },
      retry: false
    },
    'post'
  )
);

export const getNodeSchema = (clientId: string, nodeId: string) => (
  useAuthApi(
    [`get-node-schema-${clientId}-${nodeId}`],
    `/node_settings/schema/${clientId}/${nodeId}`,
    ApiBackend.BACKOFFICE,
    { enabled: !!clientId && !!nodeId }
  )
);