import { NodeSettingsContext } from "providers/NodeSettingsProvider";
import { useContext, useState, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";

import { createApiKey, getApiKeys, type ApiKey as ApiKeyProps} from "api/services/ApiKey";
import CustomCard from "components/editor/Card";

const CardContentNoPadding = styled(CardContent)(`
    padding: 1em;
    &:last-child {
      padding-bottom: 1em;
    }
  `);

function ApiKey(apiKey: ApiKeyProps) {
    return (
        <Card key={apiKey.id} variant={"outlined"}>
            <CardContentNoPadding>
                <Box display={"flex"} alignItems={"center"}>
                    <Typography variant={"overline"} fontWeight={"bold"} flex={1}>{apiKey.id}</Typography>
                    <Chip label={apiKey.role} color={"primary"} size={"small"} />
                </Box>
                <Box display={"flex"} columnGap={2}>
                    <Chip label={apiKey.ess_id == 0 ? "All assets" : apiKey.ess_id} color={"secondary"} size={"small"} />
                </Box>
            </CardContentNoPadding>
        </Card>
    )
}

function ApiKeys() {
    const { clientId, clientsNodes } = useContext(NodeSettingsContext);
    const queryClient = useQueryClient();
    const [dialogOpen, setDialogOpen] = useState(false);

    const { data, isError, isLoading } = getApiKeys(clientId);

    const onApiKeyCreationSuccess = () => {
        queryClient.invalidateQueries({ queryKey: [`client-apikeys-${clientId}`] });
    }
    const { mutate: mutateCreateApiKey } = createApiKey(clientId, onApiKeyCreationSuccess);
    const [apiKeyRole, setApiKeyRole] = useState("ess_viewer")
    const [apiKeyEssId, setApiKeyEssId] = useState(0)

    const onApiKeyCreateSubmit = (apiKeyEssId: number, apiKeyRole: string) => {
        mutateCreateApiKey({
            "role": `${apiKeyRole}`,
            "ess_id": apiKeyEssId,
        });
    };
    
    const clientEss = useMemo(() => {
        if (!clientsNodes || !clientId) return;
        const nodes = clientsNodes?.find(({id})=> id == Number(clientId))?.nodes;
        if (!nodes) return;
        return nodes
    }, [clientsNodes, clientId]);

    const Actions = useMemo(() => 
        <IconButton onClick={()=>setDialogOpen(true)}>
            <AddIcon />
        </IconButton>
    , []);
    
    // TODO: when implementing multi-assets permissions, stop using numeric IDs for assets, but show client label.
    return (
        <CustomCard title={'API keys'} actions={Actions} size={2}>
            {(isLoading) && <CircularProgress />}
            {(!isLoading && !!isError) && <span>Error loading API keys</span>}
            {(!isLoading && !isError) && (data && data.length > 0) 
                ? (
                    <Box display={"flex"} gap={2} flexDirection={"column"}>
                        {data.map(ApiKey)}
                    </Box>
                  ) 
                : <span>No current API keys</span>
            }
            <Dialog open={dialogOpen} onClose={()=>setDialogOpen(false)}>
                <DialogTitle>Create new API key</DialogTitle>
                <DialogContent>
                    <TextField select value={apiKeyRole} onChange={e => setApiKeyRole(e.target.value)} label={"Role"} fullWidth>
                        <MenuItem value={"ess_supervisor"}>Ess Supervisor (Read/Write access to one or all ESS)</MenuItem>
                        <MenuItem value={"ess_viewer"}>Ess Viewer (Read access to one or all ESS)</MenuItem>
                        <MenuItem value={"admin"}>Admin (Admin access to account. DO NOT GIVE THIS TO CLIENTS!!)</MenuItem>
                    </TextField>
                    <TextField
                        label={"ESS Permission"} 
                        type={"number"} 
                        inputProps={{
                            min: 0
                        }} 
                        value={apiKeyEssId} 
                        onChange={e => setApiKeyEssId(parseInt(e.target.value))}
                        select
                        fullWidth
                        disabled={apiKeyRole==="admin"}
                    >
                        <MenuItem value={0} divider>All</MenuItem>
                    {
                        clientEss?.map( ess =>
                            <MenuItem key={ess.nodeid} value={ess.nodeid.substring(1)}>{ess.node_name}</MenuItem>
                        )
                    }
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setDialogOpen(false)} variant={"contained"}>Cancel</Button>
                    <Button 
                        type={"submit"}
                        onClick={(ev) => { onApiKeyCreateSubmit(apiKeyEssId, apiKeyRole); ev.preventDefault(); }}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </CustomCard>)
}

export default ApiKeys