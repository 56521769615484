// Enhanced version of `@jsonforms/material-renderers/src/layouts/MaterialCategorizationLayout.tsx` :
// 1. Navigate to the path specified in `tag` property of the category when tab changes
// 2. setActiveCategory based on the last param of the url (*)
import { useState, useMemo, useEffect } from 'react';
import { AppBar, Hidden, Tab, Tabs } from '@mui/material';
import {
  and,
  Categorization,
  Category,
  deriveLabelForUISchemaElement,
  isVisible,
  RankedTester,
  rankWith,
  StatePropsOfLayout,
  Tester,
  UISchemaElement,
  uiTypeIs,
} from '@jsonforms/core';
import {
  TranslateProps,
  withJsonFormsLayoutProps,
  withTranslateProps,
} from '@jsonforms/react';
import {
  AjvProps,
  MaterialLayoutRenderer,
  MaterialLayoutRendererProps,
  withAjvProps,
} from '@jsonforms/material-renderers';
import { useNavigate, useParams } from 'react-router-dom';

interface ICategorization extends Categorization {
  index: number;
  tag: string;
  elements: (ICategory | ICategorization)[];
}
interface ICategory extends Category {
  index: number;
  tag: string;
}

export const isSingleLevelCategorization: Tester = and(
  uiTypeIs('Categorization'),
  (uischema: UISchemaElement): boolean => {
    const categorization = uischema as ICategorization;

    return (
      categorization.elements &&
      categorization.elements.reduce(
        (acc, e) => acc && e.type === 'Category',
        true
      )
    );
  }
);

export const materialCategorizationTester: RankedTester = rankWith(
  2,
  isSingleLevelCategorization
);

export interface IMaterialCategorizationLayoutRendererProps
  extends StatePropsOfLayout,
    AjvProps,
    TranslateProps {
  selected?: number;
  ownState?: boolean;
  data?: any;
  onChange?(selected: number, prevSelected: number): void;
}

export const MaterialCategorizationLayoutRenderer = (
  props: IMaterialCategorizationLayoutRendererProps
) => {
  const {
    data,
    path,
    renderers,
    cells,
    schema,
    uischema,
    visible,
    enabled,
    selected,
    onChange,
    ajv,
    t,
  } = props;

  const navigate = useNavigate();
  const params = useParams();

  const categorization = uischema as ICategorization;
  const [previousCategorization, setPreviousCategorization] =
    useState<ICategorization>(uischema as ICategorization);
  const [activeCategory, setActiveCategory] = useState<number>(selected ?? 0);
  const categories = useMemo(
    () =>
      categorization.elements.filter((category: ICategory | ICategorization) =>
        isVisible(category, data, '', ajv)
      ),
    [categorization, data, ajv]
  );

  if (categorization !== previousCategorization) {
    setActiveCategory(0);
    setPreviousCategorization(categorization);
  }

  const safeCategory =
    activeCategory >= categorization.elements.length ? 0 : activeCategory;


  const childProps: MaterialLayoutRendererProps = {
    elements: categories[safeCategory] ? categories[safeCategory].elements : [],
    schema,
    path,
    direction: 'column',
    enabled,
    visible,
    renderers,
    cells,
  };
  const onTabChange = (_event: any, value: any) => {
    // 1. Get the tag (path) of the selected category and navigate to it
    const tag = categories[value].tag;
    navigate(tag);

    if (onChange) {
      onChange(value, safeCategory);
    }
  };

  useEffect(() => {
    // 2. SetActiveCategory based on react-router last navigation param
    const activeTags = params["*"]?.split('/');
    if (!activeTags) return;
    const requestCategory = categories.findIndex(({tag, index})=>tag.endsWith(activeTags[index]));
    if (requestCategory === -1) return;

    setActiveCategory(requestCategory);
  }, [categories, params]);

  const tabLabels = useMemo(() => {
    return categories.map((e: Category | Categorization) => deriveLabelForUISchemaElement(e, t));
  }, [categories, t]);

  return (
    <Hidden xsUp={!visible}>
      <AppBar position={'static'}>
        <Tabs
          value={safeCategory}
          onChange={onTabChange}
          textColor={'inherit'}
          indicatorColor={'secondary'}
          variant={'scrollable'}
        >
          {categories.map((_, idx: number) => (
            <Tab key={idx} label={tabLabels[idx]} />
          ))}
        </Tabs>
      </AppBar>
      <div style={{ marginTop: '0.5em' }}>
        <MaterialLayoutRenderer {...childProps} key={safeCategory} />
      </div>
    </Hidden>
  );
};

export default {
  tester: materialCategorizationTester,
  renderer: withAjvProps(withTranslateProps(withJsonFormsLayoutProps(MaterialCategorizationLayoutRenderer))),
};