type Control = {
    type: string,
    scope: string,
}

type Category = {
    index: number,
    type: string,
    tag: string,
    label: string,
    elements: (Control | Categorization)[]
}

type Categorization = {
    type: string,
    elements: Category[],
};

type VendorSettingsProperties = {
    process: string
}

type VendorSettings = {
    properties: VendorSettingsProperties[]
}

type SchemaProperties = {
    vendorsettings: VendorSettings
}

export type Schema = {
    properties: SchemaProperties
}

export type UiSchema = {
    type: string,
    elements: object[],
}

const categories_labels: Record<string, string> = {
    "battery_spec": "Battery Specifications",
    "incoming_data/storage": "Storage Accounts",
    "usage/out_of_spec": "Out of Nominal Conditions",
    "sos/overvoltage": "Over Voltage",
    "sos/undervoltage": "Under Voltage",
    "sos/overtemperature": "Over Temperature",
    "sos/cellimbalance": "Cell Imbalance",
    "sos/cellimbalance/a": "Type A",
    "sos/cellimbalance/c": "Type C",
    "sos/cellimbalance/d": "Type D",
    "sos/cellimbalance/e": "Type E",
    "sos/cellimbalance/f": "Type F",
    "sos/cellimbalance/h": "Type H",
    "sos/cellimbalance/c/eligibility_pocv": "Eligibility pseudo OCV",
    "soh/pocv": "Pseudo OCV",
};

function toTitle(path: string) {
  if (Object.prototype.hasOwnProperty.call(categories_labels, path))
    return categories_labels[path];

  const lastSubSection = path.substring(path.lastIndexOf('/')+1).replaceAll('_', ' ')
  const title = lastSubSection.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
  return title.length <= 3 ? title.toUpperCase() : title;
}

const createSubCategory = function (path: Array<string>, index: number, categorization: Categorization): Category {
    const current_path = path.slice(0, index + 1).join("/")

    let existing_category = categorization.elements.find(c => c.tag === current_path)
    if (!existing_category) {
        existing_category = {
            "index": index,
            "type": "Category",
            "tag": current_path,
            "label": toTitle(current_path),
            "elements": [],
        };
        categorization["elements"].push(existing_category);
    }

    if (index === path.length - 1) {
        return (existing_category)
    } else {
        let sub_categorization = existing_category["elements"].find(e => e["type"] === "Categorization")
        if (!sub_categorization) {
            sub_categorization = {
                "type": "Categorization",
                "elements": [],
            }
            existing_category["elements"].push(sub_categorization)
        }
        return (createSubCategory(path, index + 1, sub_categorization as Categorization))
    }
}


export const generateUiSchema = (schema: Schema): UiSchema => {
    const output: Categorization = {
        type: "Categorization",
        "elements": [
            {
                "index": 0,
                "type": "Category",
                "label": "User Settings",
                "tag": "usersettings",
                "elements": [
                    {
                        "type": "Control",
                        "scope": "#/properties/usersettings"
                    },
                ]
            },
        ]
    };

    if (schema) {

        for (const [key, value] of Object.entries(schema.properties.vendorsettings.properties)) {
            const category_path = value.process.split("/");

            const category = createSubCategory(category_path, 0, output);
            category["elements"].push({
                "type": "Control",
                "scope": "#/properties/vendorsettings/properties/" + key,
            });
        }
    }

    return (output);
}