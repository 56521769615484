import { forwardRef, useContext, useEffect, useMemo, useRef } from 'react';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { NavLink, useParams } from 'react-router-dom';
import cx from 'classnames';

import styles from './LeftSidebar.module.scss';
import { Client, Node } from 'types';
import { CircularProgress, IconButton } from '@mui/material';
import { NodeSettingsContext } from 'providers/NodeSettingsProvider';

import SettingsIcon from '@mui/icons-material/Settings';

type GoToSettingsProps = {
  clientId: number;
  nodeId: string;
}

const GoToSettings = forwardRef<HTMLAnchorElement, GoToSettingsProps>(
  function GoToSettingsWithRef({clientId, nodeId, ...props}, ref) {
    const params = useParams();
    const activeTags = params["*"]?.split('/');
    const activeTagsPath = activeTags ? '/' + activeTags.join('/') : '';

    return <NavLink {...props} to={`/settings/${clientId}/${nodeId}${activeTagsPath}`} ref={ref} />;
  }
);

function ClientLabel({ name, clientId }: { name: string, clientId: number }) {
  const to = `/settings/${clientId}`;
  
  return (
    <div className={cx(styles.clientLabel, { [styles.active]: location.pathname === to })}>
      <div>{name}</div>
      <div className={styles.settingsIcon}>
        <NavLink to={to} onClick={e=>e.stopPropagation()}>
          <IconButton size={"small"}>
            <SettingsIcon />
          </IconButton>
        </NavLink>
      </div>
    </div>
  );
}

function LeftSidebar() {
  const sidebarRef = useRef<HTMLHtmlElement>(null);
  const { clientsNodes, clientId: selectedClientId } = useContext(NodeSettingsContext);

  useEffect(() => {
    if (!sidebarRef.current) return;
    const activeElement = sidebarRef.current?.querySelector('.ps-open');

    if (activeElement) {
      activeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [sidebarRef.current])
  
  const renderNode = (node: Node, clientId: number) => {
    if (node.children) {
      return (
        <SubMenu
          label={node.node_name ?? `id: ${node.nodeid}`}
          key={`${clientId}-${node.nodeid}-sub`}
          component={<NavLink to={`/settings/${clientId}/${node.nodeid}`}/>}
        >
          {node.children.map((subNode: Node) => renderNode(subNode, clientId))}
        </SubMenu>
      )
    } else {
      return (
        <MenuItem
          key={`${clientId}-${node.nodeid}-item`}
          component={<GoToSettings clientId={clientId} nodeId={node.nodeid} />}
          suffix={node.nodeid}
        >
          {node.node_name}
        </MenuItem>
      )
    }
  };

  const renderClients = useMemo(() => {
    return (
      <>
        {clientsNodes?.map((client: Client) =>
          <SubMenu
            key={client.id}
            label={<ClientLabel name={client.name} clientId={client.id} />}
            suffix={client.id}
            defaultOpen={Number(selectedClientId) === client.id}
            className={styles.submenu}
          >
            {client.nodes?.map((node: Node) => renderNode(node, client.id))}
          </SubMenu>
        )}
      </>
    )
  }, [clientsNodes, selectedClientId]);


  return (
    <>
      {!clientsNodes ?
        <div className={styles.spinner}>
          <CircularProgress />
        </div>
        :
        <Sidebar
        ref={sidebarRef}
        breakPoint={"sm"}
        transitionDuration={800}
        rtl={false}
        backgroundColor={"#fafafa"}
        width={"300px"}
        >
          <Menu
          menuItemStyles={{
            suffix: {"font-size": "smaller"},
            button: {
              [`&.active`]: {
                fontWeight: "bold",
              },
            }
          }}>
            {renderClients}
          </Menu>
        </Sidebar>
      }
    </>
  )
}

export default LeftSidebar;