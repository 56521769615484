import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginRequest } from './config';

function PrivateRoute({ children }: { children: JSX.Element }) {
  const { instance, inProgress } = useMsal();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (inProgress === InteractionStatus.HandleRedirect) {

      (async () => {
        const response = await instance.handleRedirectPromise();

        if (location.pathname !== response?.state) {
          navigate((response?.state || '/settings'), {
            replace: true,
          });
        }
      })()
    }
  }, [inProgress]);

  return (
    <MsalAuthenticationTemplate 
      interactionType={InteractionType.Redirect} 
      authenticationRequest={{
        ...loginRequest,
        state: location.pathname,
      }}
      errorComponent={undefined} 
      loadingComponent={undefined}>
      {children}
    </MsalAuthenticationTemplate>

  )
}

export default PrivateRoute;
