import CircularProgress from "@mui/material/CircularProgress";
import { JsonForms } from '@jsonforms/react';
import { getNodeData, getQaNodeData, getNodeSchema, postNodeData } from 'api/services/NodeSettings';
import { NodeSettingsContext } from "providers/NodeSettingsProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import { generateUiSchema, Schema } from './generateUiSchema';
import style from './Editor.module.scss';
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { ErrorObject } from 'ajv';
import Ajv from "ajv";
import categorizationRenderer from "./CategorizationLayout";
import { SnackbarKey, useSnackbar } from "notistack";

const renderers = [
  ...materialRenderers,
  categorizationRenderer,
]

function NodeEditor() {
  const { nodeId, clientId, name, saveSettings } = useContext(NodeSettingsContext);
  const { data, error, isLoading } = getNodeData(clientId, nodeId);
  const { data: qaData, error: qaError, isLoading: qaIsLoading } = getQaNodeData(clientId, nodeId);
  const { data: schema } = getNodeSchema(clientId, nodeId);
  const uiSchema = useMemo(() => generateUiSchema(schema as unknown as Schema), [schema]);
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<ErrorObject[]>([]);
  const { mutate: updateSettings, isPending: updateInProgress } = postNodeData(clientId ?? "", nodeId ?? "", saveSettings);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [displayedErrors, setDisplayedErrors] = useState<SnackbarKey[]>([]);

  const ajv_with_defaults = new Ajv({ useDefaults: true, strict: false })

  useEffect(() => {
    if (data && data.has_settings) {
      setFormData(data.node_settings)
    } else if (schema && data && !data.has_settings) {
      const validate = ajv_with_defaults.compile(schema);
      const default_data = { "nodeid": nodeId, "client_id": parseInt(clientId), 'usersettings': {}, 'vendorsettings': {} };
      validate(default_data);
      setFormData(default_data);
    } else {
      // Reset formData on node change
      setFormData({});
    }
  }, [data, schema, clientId, nodeId])

  const onQaImport = () => {
    if (qaData && qaData.has_settings) {
      setFormData(qaData.node_settings);
      enqueueSnackbar(`Import to local form has succeeded. To validate changes, CLICK ON SUBMIT!`, {
        variant: 'success',
        action: () => null,
      });
    } else {
      enqueueSnackbar(`QA import failed. Node has settings in QA? = ${qaData.has_settings}`, {
        variant: 'error', 
        persist: true,
      });
    }
  };
  useEffect(() => {
    errors.forEach(e => {
      const key = enqueueSnackbar(`${e.instancePath}: ${e.message}`, {
        preventDuplicate: true,
        key: e.schemaPath,
        variant: 'error', 
        persist: true,
      });
      setDisplayedErrors(prevKeys => [...prevKeys, key]);
    });

    displayedErrors.forEach(key => {
      if (!errors.some(error => error.schemaPath === key)) {
        closeSnackbar(key);
        setDisplayedErrors(prevKeys => prevKeys.filter(k => k !== key));
      }
    });
  }, [errors]);

  useEffect(() => {
    return () => {
      displayedErrors.forEach(key => closeSnackbar(key));
    }
  }, [])

  const onSubmit = () => {
    updateSettings(formData);
  };

  return (
    <div className={style.editor}>
      <div className={style.header}>
        <h1>{name}</h1>
        <Button color={"secondary"} onClick={onQaImport} variant={'outlined'} disabled={!!qaError || !!qaIsLoading} size={"small"}>
          QA Import
        </Button>
      </div>
      <>
        {isLoading && <CircularProgress />}
        {!isLoading && schema && data &&
          <>
            {!data.has_settings &&
              <div className={style.no_settings_warning}>
                Node has no current settings.
                Defaults have been injected.
                Fill and submit the form to create settings.
              </div>
            }
            <JsonForms
              schema={schema}
              uischema={uiSchema}
              data={formData}
              renderers={renderers}
              cells={materialCells}
              onChange={({ errors, data }) => {
                errors && setErrors(errors);
                if (data && Object.keys(data).length > 0) {
                  setFormData(data);
                }
              }}
              config={
                { "showUnfocusedDescription": true }
              }
            />
            <Fab
              color={"primary"}
              sx={{
                position: 'fixed',
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(2),
              }}
              disabled={errors.length > 0 || updateInProgress} 
              onClick={onSubmit}
            >
              SAVE
            </Fab>
          </>
        }
        {error && <div>Error getting settings</div>}
      </>
    </div>
  )
}

export default NodeEditor;