import { AccountInfo, BrowserAuthError, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import { loginRequest } from 'auth/config';
import axios from 'axios';
import { QueryKey, MutationKey, UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

const axiosInstance = axios.create();

export enum ApiBackend {
  BACKOFFICE,
  QA_BACKOFFICE,
  BIM,
};

const baseURLs = {
  [ApiBackend.BACKOFFICE]: process.env.REACT_APP_API_URL,
  [ApiBackend.QA_BACKOFFICE]: process.env.REACT_APP_QA_API_URL,
  [ApiBackend.BIM]: process.env.REACT_APP_BIM_API_URL,
}

export const useAuthApi = <TData=any,TSelect=TData,TError=unknown>(
  requestKey: QueryKey,
  url: string,
  apiBackend: ApiBackend,
  options?: Omit<UseQueryOptions<TData,TError,TSelect>, 'queryKey' | 'queryFn'>,
  axiosOptions?: object,
) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  return useQuery<TData, TError, TSelect>({
    queryKey: requestKey,
    queryFn: () =>
      instance
        .acquireTokenSilent({
          scopes: [],
          account: account as AccountInfo,
        })
        .then((response) => axiosInstance({
          ...axiosOptions,
          baseURL: baseURLs[apiBackend],
          method: 'get',
          url: url,
          headers: { Authorization: `Bearer ${response.accessToken}` },
        }).then((response) => {
          return response?.data;
        }))
        .catch((error) => {
          if (inProgress === InteractionStatus.None 
              && error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(loginRequest);
          }
          return Promise.reject(error);
        }),
      ...options,
      enabled: Boolean(account) && inProgress === 'none' && options && options.enabled,
    },
  );
};

export const useAuthMutation = (
  mutationKey: MutationKey,
  url: string,
  apiBackend: ApiBackend,
  options?: Omit<UseMutationOptions<unknown, unknown, any, QueryKey>, 'queryKey' | 'queryFn'> | undefined,
  method = 'get',
  additionnalHeaders = {},
) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  return useMutation({
    mutationKey,
    mutationFn: (data) =>
      instance
        .acquireTokenSilent({
          scopes: loginRequest.scopes,
          account: account as AccountInfo,
        })
        .then((response) => axiosInstance({
          baseURL: baseURLs[apiBackend],
          method: method,
          url: url,
          headers: Object.assign({ Authorization: `Bearer ${response.accessToken}` }, additionnalHeaders),
          data: data,
        }))
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(loginRequest);
          }
          return Promise.reject(error);
        }),
      ...options,
    },
  );
};
