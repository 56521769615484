import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { CssBaseline, ThemeProvider, createTheme, Button } from '@mui/material';
import { SnackbarProvider, closeSnackbar } from "notistack";
import { ProSidebarProvider } from 'react-pro-sidebar';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import config from 'auth/config';
import Router from './Router';

import './App.css';


const queryClient = new QueryClient();

function App() {

  /**
   * Customize form so each control has more space
   */
  const theme = createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: '0.8em 0',
          },
        }
      },
    },
  });

  const pca = new PublicClientApplication(config);

  const accounts = pca.getAllAccounts();
  if (accounts.length > 0) {
    pca.setActiveAccount(accounts[0]);
  }

  pca.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      pca.setActiveAccount(account);
    }
  });

  return (
    <MsalProvider instance={pca}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <ProSidebarProvider>
            <SnackbarProvider
              maxSnack={10}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              classes={{containerRoot: 'snackbar-container-root'}}
              autoHideDuration={3000}
              action={(snackbarId) => (
                <Button variant={'text'} color={'inherit'} onClick={() => closeSnackbar(snackbarId)}>
                  Dismiss
                </Button>
              )}
            >
              <Router />
            </SnackbarProvider>
          </ProSidebarProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
