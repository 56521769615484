import { useAuthApi, useAuthMutation, ApiBackend } from "api";

export type ApiKey = {
  id: string
  client_id: number
  role: string
  ess_id: number
};

export const getApiKeys = (clientId: string) =>
  useAuthApi<ApiKey[]>([`client-apikeys-${clientId}`], `/clients/${clientId}/api_keys`, ApiBackend.BACKOFFICE, {
    enabled: !!clientId,
  });

export const createApiKey = (clientId: string, callback?: () => void) =>
  useAuthMutation(
    [`post-api_keys-${clientId}`],
    `/clients/${clientId}/api_keys`,
    ApiBackend.BACKOFFICE,
    {
      onSuccess: () => {
        alert('API key created');
        if (callback) callback();
      },
      onError: () => {
        alert('Error: Unable to create API key');
      },
      retry: false
    },
    "post",
    { "content-type": "application/json" },
  );