import PrivateRoute from 'auth/PrivateRoute';
import EditorRouter from 'pages/EditorRouter';
import {NodeEditor, ClientCreator, ClientEditor} from './editor';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NodeSettingsProvider } from 'providers/NodeSettingsProvider';

function Router() {

  return (
    <Routes>
      <Route path={'/'} element={
        <PrivateRoute>
          <Navigate to={'/settings'} replace />
        </PrivateRoute>
      } />
      <Route
        element={
          <PrivateRoute>
            <NodeSettingsProvider>
              <EditorRouter />
            </NodeSettingsProvider>
          </PrivateRoute>
        }>
          <Route path={"create-client"} element={<ClientCreator/>} />
          <Route path={"settings"}>
            <Route path={":clientId/:nodeId/*?"} element={<NodeEditor />} />       
            <Route path={":clientId"} element={<ClientEditor />} />
          </Route>
      </Route>
    </Routes>
  );
}

export default Router;
